<template>
  <v-navigation-drawer v-model="drawer" app dark temporary>
    <v-list>
      <div v-for="(link, i) in links" :key="i">
        <!-- If the link has children, use v-list-group for a collapsible sub-menu -->
        <v-list-group v-if="link.children" no-action>
          <template v-slot:activator>
            <v-list-item :href="link.href" @click="onClick($event, link)">
              <v-list-item-content>
                <v-list-item-title v-text="link.text" />
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="(child, index) in link.children"
            :key="`child-${index}`"
            :to="child.to"
            :href="child.href"
            @click="onClick($event, child)"
          >
            <v-list-item-title v-text="child.text" />
          </v-list-item>
        </v-list-group>

        <!-- Render as a standard list item if there are no children -->
        <v-list-item
          v-else
          :to="link.to"
          :href="link.href"
          @click="onClick($event, link)"
        >
          <v-list-item-content>
            <v-list-item-title v-text="link.text" />
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CoreDrawer",

  computed: {
    ...mapGetters(["links"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
  },

  methods: {
    ...mapMutations(["setDrawer"]),
    onClick(e, item) {
      e.stopPropagation();

      if (item.to === "/") {
        this.$vuetify.goTo(0);
        this.setDrawer(false);
        return;
      }

      if (!item.to || !item.href) return;

      this.$vuetify.goTo(item.href);
      this.setDrawer(false);
    },
  },
};
</script>

<style scoped>
.v-list-group .v-list-group__header .v-list-item {
  padding-left: 0px !important;
}

.v-list-group .v-list-group__items > .v-list-item {
  padding-left: 30px !important;
}
</style>
